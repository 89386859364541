import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/playtest/page.scss");
;
import(/* webpackMode: "eager" */ "/app/src/src/entities/game/ui/GameFrame/GameFrameContent.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/src/widgets/GamesFrames/ui/GamesFrames.scss");
;
import(/* webpackMode: "eager" */ "/app/src/src/entities/game/ui/GameFrame/GameFrame.scss");
;
import(/* webpackMode: "eager" */ "/app/src/src/widgets/GamesFrames/ui/GamesFramesContentClient.tsx");

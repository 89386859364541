'use client'

import { useGamesFramesPlaytest } from '@/src/entities/game/hooks/useGamesFramesPlaytest'
import { GameFrame } from '@/src/entities/game/ui/GameFrame'
import { BreakpointsMd } from '@/src/shared/config/variables'

type IGameGridProps = {
  maxVisibleCount?: number
  priorityImages?: boolean
}

export default function GamesGridContent({
  maxVisibleCount,
  priorityImages = false,
}: IGameGridProps) {
  const { games } = useGamesFramesPlaytest()

  return (
    <>
      {games.map((game, key) => {
        if (maxVisibleCount && key >= maxVisibleCount) return

        const priority = priorityImages && key < 3

        return (
          <div className="games-frames__cell" key={game.id}>
            <GameFrame
              withoutContainer
              pathname="/"
              priority={priority}
              sizes={`(max-width: ${BreakpointsMd}) 90vw, 50vw`}
              game={game}
            />
          </div>
        )
      })}
    </>
  )
}
